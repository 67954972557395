import { FC, ReactNode } from 'react';
import { InputNumber } from 'antd';
import styled from 'styled-components';
import { noop } from 'lodash';
import { TruckIcon } from '@pages/OrderCreatePage/sections/DeliveryDetailsSection/components/TruckIcon';

const StyledInput = styled(InputNumber)`
  width: 68px !important;
`;

const Row = styled.div`
  display: flex;
  max-width: 150px;
  gap: 20px;
  align-items: center;
  > div {
    flex: 1;
  }
`;

interface Props {
  value?: number;
  onChange?: (value: number) => void;
  index: number;
  max: number;
  min: number;
  step: number;
  disabled?: boolean;
  inputElememt?: ReactNode;
}

export const TruckItem: FC<Props> = ({ index, value = 0, onChange = noop, disabled = false, inputElememt }) => {
  return (
    <Row>
      <TruckIcon index={index} />
      {inputElememt || (
        <StyledInput
          disabled={disabled}
          value={value}
          formatter={value => value?.toString() ?? ''}
          onChange={val => val != null && onChange(val as number)}
          controls={false}
          min={0}
        />
      )}
    </Row>
  );
};
