import { FC } from 'react';
import styled from 'styled-components';
import { gettext } from '@cranium/i18n';

type TruckIconProps = {
  index: number;
};

const Main = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px 4px 4px;
  background: var(--gray-9);
  color: var(--white);
  font: var(--font-semibold-14);
  border-radius: 17px;
  flex-wrap: nowrap;
  white-space: nowrap;
`;

const Root = styled.div`
  display: flex;
  align-items: flex-start;
`;
const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
  width: 24px;
  height: 24px;
  border-radius: 12px;
  i {
    color: var(--gray-9);
    font-size: 12px;
  }
`;
export const TruckIcon: FC<TruckIconProps> = ({ index }) => {
  return (
    <Root className="truck-item">
      <Main>
        <Icon>
          <i className="icon-delivery-truck" />
        </Icon>
        {index}
      </Main>
    </Root>
  );
};
