import React from 'react';
import { NavLinkItem } from '@components/layout/Sidebar/components/NavLinkItem';
import { MessagesNavItem, PayoutRequestNavItem, RequestNavItem } from '@components/layout/Sidebar/components/customNavItems';
import { useGettext } from '@cranium/i18n';
import { IdentityTypeEnum } from '@/interfaces/brokrete';
import { usePermissions, useClientSettings, usePartnerData } from '@/shared/access';
import { get } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { Link } from '@/shared/navigation';
import { useLocalStorage } from '@/core/Storage';

export interface MenuItem {
  name: string;
  allowed: boolean;
  withProtectedRenderer?: IdentityTypeEnum[];
  component: JSX.Element;
}

type SidebarStructure = Array<
  { type: 'single'; item: MenuItem } | { type: 'grouped'; title: string; openByDefault: boolean; items: MenuItem[] }
>;

export const useSidebarStructure = () => {
  const permissions = usePermissions();
  const settings = useClientSettings();
  const partnerData = usePartnerData();
  const { gettext } = useGettext();
  const scheduleQuery = useLocalStorage<string>(`LAST_KNOWN_SCHEDULE_FILTERS-${partnerData?.id || ''}.v1`);

  const menuItems: Record<string, MenuItem> = {
    orders: {
      name: 'orders',
      allowed: get(permissions, 'orders.show'),
      component: <NavLinkItem iconClass="icon-orders" label={gettext('Orders')} path="/orders" userTipName="sidebar.orders" />
    },
    schedule: {
      name: 'schedule',
      allowed: get(permissions, 'scheduling.show'),
      withProtectedRenderer: ['partner', 'super_admin'],
      component: (
        <NavLinkItem
          iconClass="icon-schedule2"
          label={gettext('Schedule')}
          path={['/schedule/drivers', scheduleQuery.value].filter(Boolean).join('')}
          userTipName="sidebar.schedule"
        />
      )
    },
    drivers: {
      name: 'drivers',
      allowed: get(permissions, 'drivers.show'),
      withProtectedRenderer: ['partner', 'super_admin'],
      component: (
        <NavLinkItem iconClass="icon-driver" label={gettext('Drivers')} path="/drivers" exact userTipName="sidebar.drivers" />
      )
    },
    deliveries: {
      name: 'deliveries',
      allowed: get(permissions, 'driver_tasks.show') && get(settings, 'driver_tasks.visible'),
      withProtectedRenderer: ['partner', 'super_admin'],
      component: (
        <NavLinkItem
          iconClass="icon-time-table"
          label={gettext('Deliveries')}
          path="/deliveries"
          userTipName="sidebar.deliveries"
        />
      )
    },
    trucks: {
      name: 'trucks',
      allowed: true,
      component: <NavLinkItem iconClass="icon-truck2" label={gettext('Trucks')} path="/trucks" userTipName="sidebar.trucks" />
    },
    finance: {
      name: 'finance',
      allowed: get(permissions, 'finances.show'),
      withProtectedRenderer: ['partner', 'super_admin'],
      component: <PayoutRequestNavItem />
    },
    tax: {
      name: 'tax',
      allowed: get(permissions, 'taxes.show'),
      withProtectedRenderer: ['partner', 'super_admin'],
      component: <NavLinkItem iconClass="icon-dollar-card" label={gettext('Tax')} path="/tax" userTipName="sidebar.tax" />
    },
    projects: {
      name: 'projects',
      allowed: get(permissions, 'projects.show'),
      withProtectedRenderer: ['partner', 'super_admin'],
      component: (
        <NavLinkItem iconClass="icon-project-1" label={gettext('Projects')} path="/projects" userTipName="sidebar.projects" />
      )
    },
    pallets: {
      name: 'pallets',
      allowed: get(permissions, 'pallets.show') && get(settings, 'pallets.visible'),
      withProtectedRenderer: ['partner', 'super_admin'],
      component: <NavLinkItem iconClass="icon-pallets" label={gettext('Pallets')} path="/pallets" userTipName="sidebar.pallets" />
    },
    products: {
      name: 'products',
      allowed: get(permissions, 'products.show'),
      withProtectedRenderer: ['partner', 'super_admin'],
      component: (
        <NavLinkItem iconClass="icon-pie-chart" label={gettext('Products')} path="/products" userTipName="sidebar.products" />
      )
    },
    locations: {
      name: 'locations',
      allowed: get(permissions, 'locations.show'),
      withProtectedRenderer: ['partner', 'super_admin'],
      component: (
        <NavLinkItem
          iconClass="icon-factory"
          label={gettext('Locations')}
          path="/locations/list"
          userTipName="sidebar.locations"
        />
      )
    },
    contractors: {
      name: 'contractors',
      allowed: get(permissions, 'contractors.show'),
      withProtectedRenderer: ['partner', 'super_admin'],
      component: (
        <NavLinkItem
          iconClass="icon-contractors"
          label={gettext('Contractors')}
          path="/contractors"
          userTipName="sidebar.contractors"
        />
      )
    },
    messages: {
      name: 'messages',
      allowed: get(permissions, 'messages.show'),
      component: <MessagesNavItem />
    },
    availability: {
      name: 'availability',
      allowed: get(permissions, 'block_off_availability.show'),
      component: (
        <NavLinkItem
          iconClass="icon-block-off-availability"
          label={gettext('Block-Off Availability')}
          path="/availability/block-off"
          userTipName="sidebar.blockOffAvailability"
        />
      )
    },
    widgets: {
      name: 'widgets',
      allowed: get(permissions, 'widgets.show'),
      withProtectedRenderer: ['partner', 'super_admin'],
      component: <NavLinkItem iconClass="icon-widget" label={gettext('Widgets')} path="/widgets" userTipName="sidebar.widgets" />
    },
    requests: {
      name: 'requests',
      allowed: get(settings, 'requests.visible') && get(permissions, 'requests.show'),
      component: <RequestNavItem />
    },
    suppliers: {
      name: 'suppliers',
      allowed: get(settings, 'suppliers.visible') && get(permissions, 'suppliers.show'),
      withProtectedRenderer: ['partner', 'super_admin'],
      component: (
        <NavLinkItem iconClass="icon-suppliers" label={gettext('Suppliers')} path="/suppliers" userTipName="sidebar.suppliers" />
      )
    },
    inventory: {
      name: 'inventory',
      allowed: settings.inventory.visible && get(permissions, 'inventory.show') && !!settings.inventory.redirect_to,
      component: (
        <NavLinkItem
          iconClass="icon-store-mall-directory"
          path=""
          href={settings.inventory.redirect_to || ''}
          label={gettext('Inventory')}
          // userTipName="sidebar.inventory"
        />
      )
    },
    analytics: {
      name: 'analytics',
      allowed: settings.analytics.visible && get(permissions, 'analytics.show') && !isEmpty(settings.analytics.redirect_to),
      component: (
        <NavLinkItem
          iconClass="icon-line-chart"
          path=""
          href={settings.analytics.redirect_to || ''}
          label={gettext('Analytics')}
          // userTipName="sidebar.analytics"
        />
      )
    },
    autoCollect: {
      name: 'portal',
      allowed: get(permissions, 'auto_collect.show') && settings.auto_collect.visible && !!settings.auto_collect.redirect_to,
      component: (
        <NavLinkItem
          iconClass="icon-global"
          path=""
          href={settings.auto_collect.redirect_to || ''}
          label={gettext('Portal')}
          userTipName="sidebar.portal"
        />
      )
    }
  };

  const structure: SidebarStructure = [
    { type: 'single', item: menuItems.orders },
    {
      type: 'grouped',
      title: gettext('Delivery Section'),
      openByDefault: true,
      items: [menuItems.schedule, menuItems.drivers, menuItems.deliveries, menuItems.trucks]
    },
    { type: 'single', item: menuItems.finance },
    { type: 'single', item: menuItems.projects },
    { type: 'single', item: menuItems.pallets },
    { type: 'single', item: menuItems.contractors },
    { type: 'single', item: menuItems.suppliers },
    { type: 'single', item: menuItems.messages },
    {
      type: 'grouped',
      title: gettext('Settings'),
      openByDefault: false,
      items: [
        menuItems.locations,
        menuItems.products,
        menuItems.availability,
        menuItems.tax,
        menuItems.widgets,
        menuItems.requests
      ]
    },
    { type: 'single', item: menuItems.inventory },
    { type: 'single', item: menuItems.analytics },
    { type: 'single', item: menuItems.autoCollect }
  ];

  return structure;
};
