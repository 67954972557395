import { useCallback, useEffect, useRef, useState } from 'react';
import { useIsMounted } from '@/view/hooks/useIsMounted';
import { Apollo } from '@/core/gql/apollo';
import { get } from 'lodash';
import gql from 'graphql-tag';
import { getDevTools } from '@/shared/devTools';

type RequestFetcher = [{ count: number }, { start: () => void; stop: () => void; reFetch: () => void }];

export const useUnreadMessagesCountFetcher: (props?: {
  timeout?: number | 20;
  vars?: Partial<{ partners: Array<{ id: string }> }>;
}) => RequestFetcher = ({ timeout = 20, vars = {} } = {}) => {
  const [requestCount, setRequestCount] = useState<{ count: number }>({ count: 0 });
  const tickRef = useRef(timeout);
  const variablesRef = useRef(vars);
  const optionsRef = useRef({ isWorking: true });
  const isMounted = useIsMounted();
  variablesRef.current = vars;

  const reFetch = useCallback(() => {
    tickRef.current = 0;
  }, []);
  const stop = useCallback(() => {
    optionsRef.current.isWorking = false;
  }, []);
  const start = useCallback(() => {
    optionsRef.current.isWorking = true;
    tickRef.current = 0;
  }, []);

  useEffect(() => {
    let loading = false;
    const interval = setInterval(() => {
      if (loading || !optionsRef.current.isWorking) return;
      tickRef.current--;
      if (!loading && tickRef.current <= 0) {
        loading = true;
        if (getDevTools()?.backgroundFetchEnabled === true) {
          loading = false;
          return;
        }
        Apollo.client
          .query<RequestResult>({
            query: channelsQuery,
            variables: { unreadOnly: true, ...variablesRef.current }
          })
          .then(res => {
            if (isMounted.current) {
              const count = get(res, 'data.channels.count', 0);
              setRequestCount({ count });
              tickRef.current = timeout;
              loading = false;
            }
          });
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return [requestCount, { reFetch, start, stop }];
};
export default useUnreadMessagesCountFetcher;

type RequestResult = { channels: { count: number } };

const channelsQuery = gql`
  query channels($unreadOnly: Boolean, $partners: [IdInput!]) {
    channels(unreadOnly: $unreadOnly, partners: $partners) {
      count
    }
  }
`;
