import gql from 'graphql-tag';

export const plant = gql`
  query plant($id: ID!) {
    plant(id: $id) {
      id
      name
      timezone
      productCategories {
        id
        name
        industry
        permissions {
          decorates
          deliveryFees
          options
          productColors
          productModifications
          seasonalFees
          trucks
          underloadFees
          washoutFees
        }
      }
      address {
        display
        location {
          latitude
          longitude
        }
        state
        city
        zip
      }
      supplier {
        id
      }
      productDecorates {
        count
        values {
          active
          productDecorate {
            id
            name
            category {
              name
              industry
            }
          }
        }
      }
      productTypePrices {
        price {
          id
          currency
          value
        }
        productType {
          id
          category {
            id
          }
        }
      }
      productTypes {
        count
        groups
        values {
          active
          productType {
            id
            name
            group
            category {
              id
              name
              industry
            }
            options {
              label
              values {
                id
                description
                value
              }
            }
          }
        }
      }
    }
  }
`;

export const plants = gql`
  query plants($region: RegionInput, $supplier: [IdInput!]) {
    plants(region: $region, supplier: $supplier) {
      count
      values {
        id
        name
        timezone
        productCategories {
          id
          name
          industry
        }
        supplier {
          id
        }
        productDecorates {
          count
          values {
            active
            productDecorate {
              id
              name
              category {
                name
                industry
              }
            }
          }
        }
        productTypePrices {
          price {
            id
            currency
            value
          }
          productType {
            id
            category {
              id
            }
          }
        }
        productTypes {
          count
          groups
          values {
            active
            productType {
              id
              name
              group
              category {
                id
                name
                industry
              }
              options {
                label
                values {
                  id
                  description
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const plantsMinimal = gql`
  query plants($region: RegionInput, $supplier: [IdInput!]) {
    plants(region: $region, supplier: $supplier) {
      count
      values {
        id
        name
        timezone
      }
    }
  }
`;

export const dashboardPlant = gql`
  query plant($id: ID!) {
    plant(id: $id) {
      id
      name
      timezone
      productCategories {
        id
        name
        industry
      }
      address {
        display
        location {
          latitude
          longitude
        }
      }
      supplier {
        id
      }
    }
  }
`;

export const orderPreparatoryConfigPlants = gql`
  query orderPreparatoryConfig(
    $region: RegionInput
    $withPossibles: Boolean
    $pickup: Boolean
    $supplier: IdInput
    $project: IdInput
    $location: LocationInput
    $category: IdInput
  ) {
    orderPreparatoryConfig(input: { project: $project, category: $category }) {
      plants(region: $region, location: $location, withPossibles: $withPossibles, supplier: $supplier, pickup: $pickup) {
        id
        name
        timezone
      }
    }
  }
`;
