import gql from 'graphql-tag';
import { address, card, city, orderPrice, product, supplier } from '@/core/gql/fragments';

const plantFragment = gql`
  fragment plantFragment on Plant {
    id
    name
    timezone
    color
    productCategories {
      id
      image
      name
      industry
    }
    supplier {
      ...supplier
    }
    address {
      ...address
    }
    city {
      ...city
    }
    deliveryRadius
  }
  ${address}
  ${supplier}
  ${city}
`;

const orderFragment = gql`
  fragment orderFragment on Order {
    id
    deliveryMethod {
      name
    }
    address {
      ...address
    }
    contractor {
      id
      exportCode
      info {
        avatar
      }
      companyInfo {
        name
        address
        logo
      }
      owner {
        id
        identities {
          id
          provider
          uid
          confirmed
        }
        info {
          name
          avatar
        }
      }
    }
    contractorMember {
      id
      info {
        name
      }
      role
    }
    plant {
      ...plantFragment
    }
    deliveryTime
    lockToUpdateTime
    lockToCancelTime
    deliveryDetails
    timezone
    prices {
      ...orderPrice
    }
    pendingDriverOptions {
      id
      createdByUser {
        name
        driver {
          color
          info {
            name
          }
        }
      }
      quantity
      orderTruck {
        truckId
        id
      }
      option {
        name
        serviceName
      }
    }
    product {
      ...product
    }
    quantity
    timeBetweenTrucks
    totalPrice
    trucks
    trucksDrivers {
      status
      deliveryTime
      quantity
      driver {
        info {
          name
        }
        color
      }
    }
    trucksConfig {
      deliveryMethod {
        name
      }
      quantity {
        max
        min
        step
      }
      count {
        max
      }
    }
    options {
      id
      name
    }
    fees {
      id
      name
      type
    }
    status(detailed: true)
    adminNotes
    invoiceNotes
    additional
    paymentMethod {
      provider
      card {
        ...card
      }
    }
    holdLimits
    pouringTime
    hasToBeRated
    currency
    units
    project {
      id
      name
    }
    purchaseNumber
  }
  ${address}
  ${orderPrice}
  ${product}
  ${card}
  ${plantFragment}
`;

// For some reasons paymentMode and priceMode are getting ignored if they are defined in fragment
export const update = gql`
  mutation orderUpdate($order: OrderUpdateInput!, $plant: IdInput, $quiet: Boolean, $askToConfirm: Boolean) {
    orderUpdate(order: $order, plant: $plant, quiet: $quiet, askToConfirm: $askToConfirm) {
      order {
        ...orderFragment
        paymentMode
        priceMode
      }
      success
    }
  }
  ${orderFragment}
`;
export const updateAdditionalInfo = gql`
  mutation orderUpdateAdditionalInfo($additional: JSON!, $order: IdInput!) {
    orderUpdateAdditionalInfo(additional: $additional, order: $order) {
      order {
        ...orderFragment
      }
      success
    }
  }
  ${orderFragment}
`;
